import { TextObject } from "@types"
import { useAppSelector } from "../app/hooks"
import { selectConfigLanguage } from "../features/config/configSlice"

import { Heading } from "@chakra-ui/react"

export const GameHeader = (props: { text: TextObject }) => {
  const configLanguage = useAppSelector(selectConfigLanguage)
  if (props.text[configLanguage] !== undefined) {
    return <Heading>{props.text[configLanguage]}</Heading>
  } else {
    return <Heading>{props.text["en"]}</Heading>
  }
}
