export default [
  {
    "title": "Default Deck",
    "groups": [
      {
        "title": "Starting Events",
        "requirements": {
          "age": {
            "eq": 0
          }
        },
        "slides": [
          {
            "title": {
              "en": "Middle Income Family"
            },
            "description": {
              "en": "You are born to hardworking parents."
            },
            "image": "/images/01-starting-events_upper-middle-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "relationship": 15,
                  "age": 5,
                  "tags": {
                    "add": [
                      "middle_income",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Wealth"
            },
            "description": {
              "en": "You are born to wealthy parents."
            },
            "image": "/images/01-starting-events_rich-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "health": 15,
                  "age": 5,
                  "tags": {
                    "add": [
                      "wealthy",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Single Mother"
            },
            "description": {
              "en": "You are born to a single mother."
            },
            "image": "/images/01-starting-events_single-mother.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "agency": 15,
                  "age": 5,
                  "tags": {
                    "add": [
                      "single_mother",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Typical Family"
            },
            "description": {
              "en": "You are born to parents of average wealth."
            },
            "image": "/images/01-starting-events_typical-family.webp",
            "imageAlt": "placeholder",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "agency": 5,
                  "health": 5,
                  "education": 5,
                  "age": 5,
                  "tags": {
                    "add": [
                      "normal",
                      "virgin"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Early Primary School ",
        "requirements": {
          "age": {
            "gt": 4,
            "lt": 6
          }
        },
        "slides": [
          {
            "title": {
              "en": "Harsh Words"
            },
            "description": {
              "en": "You walk into your kitchen and see your dad slap your mom. What do you do?"
            },
            "image": "/images/02-early-primary-school_parents-fighting.webp",
            "imageAlt": "A black and white comic book style image of a young African girl sitting quietly while her parents fight, looking away with a pensive expression.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "single_mother"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Walk away."
                },
                "effects": {
                  "health": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Stay and watch."
                },
                "effects": {
                  "relationship": 2,
                  "tags": {
                    "add": [
                      "domestic_abuse_2"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Primary School ",
        "requirements": {
          "age": {
            "gt": 7,
            "lt": 11
          }
        },
        "slides": [
          {
            "title": {
              "en": "First Period"
            },
            "description": {
              "en": "You are sitting in class and you ask your teacher to go to the toilet. In the toilet, you notice blood in your pants for the first time. What would you do?"
            },
            "image": "/images/03-primary-school_young-girl-shocked.webp",
            "imageAlt": "A young girl looks down in shock during a trip to the bathroom, it is implied she had gotten her first menstrual cycle.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "first_period"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Approach a trusted  teacher for help"
                },
                "effects": {
                  "health": -3,
                  "tags": {
                    "add": [
                      "first_period"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Put on your pant, walk back in class and sit quietly"
                },
                "effects": {
                  "health": 3,
                  "agency": 3,
                  "tags": {
                    "add": [
                      "resourceful",
                      "self_advocate",
                      "first_period"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Stranger Offers a Ride"
            },
            "description": {
              "en": "A man approaches you while you are walking home from school and offers you a ride. What do you do?"
            },
            "image": "/images/03-primary-school_creepy-ride-offer.webp",
            "imageAlt": "A comic book style image of a man alone in in a car offers a ride to a young girl walking home alone a dirt road.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "You say yes"
                },
                "effects": {
                  "relationship": -3,
                  "tags": {
                    "add": [
                      "abduction_risk",
                      "risky_behavior"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You say Maybe"
                },
                "effects": {
                  "relationship": 2,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "self_advocacy",
                      "street_smarts"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You Say no"
                },
                "effects": {
                  "relationship": 2,
                  "agency": 2,
                  "tags": {
                    "add": [
                      "self_advocacy",
                      "street_smarts"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Caring for Younger Siblings"
            },
            "description": {
              "en": "Your parents ask you to stay home and skip school to care for your young siblings. What would you do? "
            },
            "image": "/images/03-primary-school_caring-for-younger-siblings.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia caring for a younger sibling.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "You tell your parents that you don't want to miss school"
                },
                "effects": {
                  "education": 3,
                  "tags": {
                    "add": [
                      "studious"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Agree "
                },
                "effects": {
                  "education": -2,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Late Primary School ",
        "requirements": {
          "age": {
            "gt": 8,
            "lt": 20
          }
        },
        "slides": [
          {
            "title": {
              "en": "A Bully's Demand"
            },
            "description": {
              "en": "You have been harrassed at school by boys and a male teacher. They follow you after school, and have even scared  you on several occasions and asked to date you. What do you do?"
            },
            "image": "/images/04-late-primary-school_forceful-boyfriend-request.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "You feel unsafe and reluctant to attend school and do nothing."
                },
                "effects": {
                  "health": -3,
                  "agency": -3,
                  "tags": {
                    "add": [
                      "gave_in_to_bad_boy"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You report this to a trusted teacher or your mentor"
                },
                "effects": {
                  "relationship": 2,
                  "education": 2,
                  "tags": {
                    "add": [
                      "self-reliant",
                      "fight_back"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Handsome Tour Guide"
            },
            "description": {
              "en": "You are walking to school, which takes 3 hours.  One day, a handsome, well-known safari guide offers you a ride. What do you do?"
            },
            "image": "/images/04-late-primary-school_walking-with-safari-guide.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl being asked to be a girlfriend by an older man.",
            "type": "event",
            "requirements": {
              "tags": {
                "not": [
                  "exit_handsome_safari"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Yes"
                },
                "effects": {
                  "health": -1,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "handsome_safari_2"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "No"
                },
                "effects": {
                  "health": 10,
                  "agency": 10,
                  "tags": {
                    "add": [
                      "exit_handsome_safari"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "maybe"
                },
                "effects": {
                  "health": -1,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "handsome_safari_2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Handsome Tour Guide 2"
            },
            "description": {
              "en": "He asks you if you want to be his girlfriend. What would you say?"
            },
            "image": "/images/04-late-primary-school_handsome_safari_2.png",
            "imageAlt": "Black-and-white comic book style image of a young girl being asked to be a girlfriend by an older man.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "handsome_safari_2"
                ],
                "not": [
                  "exit_handsome_safari",
                  "handsome_safari_3",
                  "handsome_safari_4"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Yes."
                },
                "effects": {
                  "health": -10,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "handsome_safari_3"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "No."
                },
                "effects": {
                  "health": 10,
                  "agency": 10,
                  "tags": {
                    "add": [
                      "exit_handsome_safari"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Handsome Tour Guide 3"
            },
            "description": {
              "en": "He puts your hand on his penis. He says that's what girlfriends do. What would you do?"
            },
            "image": "/images/04-late-primary-school_handsome_safari_3.png",
            "imageAlt": "Black-and-white comic book style image of a young girl being asked to be a girlfriend by an older man.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "handsome_safari_3"
                ],
                "not": [
                  "exit_handsome_safari",
                  "handsome_safari_4"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "You keep your hand there."
                },
                "effects": {
                  "health": -10,
                  "relationship": 1,
                  "tags": {
                    "add": [
                      "handsome_safari_4"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You ask him to stop the car and get out"
                },
                "effects": {
                  "health": 5,
                  "agency": 10,
                  "tags": {
                    "add": [
                      "handsome_safari_exit"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Standing Up to Family"
            },
            "description": {
              "en": "One night while your aunty is away, your uncle calls you to his bedroom and asks you to scratch his back."
            },
            "image": "/images/04-late-primary-school_family-boundaries.webp",
            "imageAlt": "Black-and-white comic book style image of a student feeling unsure in a situation at home.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "tags": {
                    "add": [
                      "groomed-by-uncle-pt2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Standing Up to Family 2"
            },
            "description": {
              "en": "He tells you: This is my house, you must do what I say."
            },
            "image": "/images/04-late-primary-school_family-boundaries-2.webp",
            "imageAlt": "Black-and-white comic book style image of a student feeling unsure in a situation at home.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "groomed-by-uncle-pt2"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Continue"
                },
                "effects": {
                  "tags": {
                    "add": [
                      "groomed-by-uncle-pt3"
                    ],
                    "remove": [
                      "groomed-by-uncle-pt2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Standing Up to Family 3"
            },
            "description": {
              "en": "While you are scratching his back, he grabs your hand and tells you that you need to be a good girl and pay him back for taking care of you"
            },
            "image": "/images/04-late-primary-school_family-boundaries-3.webp",
            "imageAlt": "Black-and-white comic book style image of a student feeling unsure in a situation at home.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "groomed-by-uncle-pt3"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Standing Up to Family 4"
            },
            "description": {
              "en": "He tells you  that you are now a big girl, and reaches his hand in your dress to touch your thighs and bum."
            },
            "image": "/images/04-late-primary-school_family-boundaries-4.webp",
            "imageAlt": "Black-and-white comic book style image of a student feeling unsure in a situation at home.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "groomed-by-uncle-pt4"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "You somehow are able to get away."
                },
                "effects": {
                  "agency": -20,
                  "health": -15,
                  "tags": {
                    "add": [
                      "groomed-by-uncle-pt5"
                    ],
                    "remove": [
                      "groomed-by-uncle-pt4"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You are not able to get away."
                },
                "effects": {
                  "agency": 20,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Receiving an HIV Diagnosis"
            },
            "description": {
              "en": "You have been feeling sick for months. After a clinic visit with your parents you are found with HIV. You barely understand what it mean. What will be your reaction your reaction?"
            },
            "image": "/images/04-late-primary-school_hiv-diagnosis.webp",
            "imageAlt": "Black-and-white comic book style image of a young person sitting in a clinic, learning about their diagnosis.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Fear and confused, wanting to understand more"
                },
                "effects": {
                  "agency": 1,
                  "tags": {
                    "add": [
                      "hiv-diagnosis-clarification"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You don’t believe and you fail to accept"
                },
                "effects": {
                  "agency": -15,
                  "tags": {
                    "add": [
                      "hiv-denial"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Inappropriate Comments at School"
            },
            "description": {
              "en": "An older boy from your school tells you that you have a nice bum in the presence of other pupils. What do you do?"
            },
            "image": "/images/04-late-primary-school_inappropriate-comments.webp",
            "imageAlt": "Black-and-white comic book style image of a student receiving a comment from an older peer.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "You smile and say thank you"
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": [
                      "inappropriate_comments_2"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You ignore him"
                },
                "effects": {
                  "agency": 1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "New Boyfriend"
            },
            "description": {
              "en": "You and a boy in your class started dating in Grade 10. You held hands and kissed for the first six months. He was your first kiss. You think you love him. "
            },
            "image": "/images/04-late-primary-school_new-boyfriend.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl discussing contraception options with friends.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Continue."
                },
                "effects": {
                  "agency": 2,
                  "health": 2,
                  "education": 2,
                  "tags": {
                    "add": [
                      "new_boyfriend_2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Desire for Sex"
            },
            "description": {
              "en": "After six months, you want him to touch your breasts when kissing. You ask him to do this and he wants to know if you are sure. What do you say?"
            },
            "image": "/images/04-late-primary-school_desire.webp",
            "imageAlt": "Black-and-white comic book style image of a young person contemplating their feelings about intimacy.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "new_boyfriend_2"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Tell him you want to have sex."
                },
                "effects": {
                  "agency": 1,
                  "tags": {
                    "add": [
                      "possible-pregancy",
                      "hiv-exposure",
                      "new_boyfriend_5"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Say nothing."
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Pray to God to make the feeling stop"
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Decision About Sex"
            },
            "description": {
              "en": "You tell your boyfriend you want to have sex. He says he also wants to have sex. What happens next?"
            },
            "image": "/images/04-late-primary-school_decision-about-sex.webp",
            "imageAlt": "Black-and-white comic book style image of a young couple discussing safe sex.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "new_boyfriend_5"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "You tell him even if we want to, we might not be ready. We need condoms first."
                },
                "effects": {
                  "agency": 10,
                  "health": 10,
                  "tags": {
                    "add": [
                      "took_precautions"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You agree to sleep with him without a condom the next time you are alone."
                },
                "effects": {
                  "agency": -10,
                  "health": -10,
                  "tags": {
                    "add": [
                      "risked_STI",
                      "risk_pregnacy",
                      "hiv_exposure",
                      "possible-pregancy"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Unwanted Touch in Class"
            },
            "description": {
              "en": "You are in class, sitting next to a boy. You notice he puts his hand on your thigh. What do you do?"
            },
            "image": "/images/04-late-primary-school_unwanted-touch.png",
            "imageAlt": "Black-and-white comic book style image of a young girl noticing an inappropriate touch from a classmate.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": []
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Report him to your teacher"
                },
                "effects": {
                  "agency": 2,
                  "education": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Look at him and smile"
                },
                "effects": {
                  "education": -1,
                  "tags": {
                    "add": [
                      "unwanted-touch-2"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "Say nothing"
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": [
                      "unwanted-touch-2"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Early Highschool ",
        "requirements": {
          "age": {
            "gt": 14,
            "lt": 18
          }
        },
        "slides": [
          {
            "title": {
              "en": "Breast Development"
            },
            "description": {
              "en": "You notice your breasts are getting bigger. A boy at school notices and whispers about you at break. What do you do?"
            },
            "image": "/images/05-early-highschool_breast-development.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl noticing changes in her body while boys whisper at school.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": []
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Ignore him"
                },
                "effects": {
                  "agency": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Go hide in the toilet"
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "High School ",
        "requirements": {
          "age": {
            "gt": 13,
            "lt": 24
          }
        },
        "slides": [
          {
            "title": {
              "en": "Noticing Body Changes"
            },
            "description": {
              "en": "You notice changes in your body and you realise that you are approaching puberty, shortly you start having periods. What do you do?"
            },
            "image": "/images/06-highschool_body-changes.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl noticing changes in her body as she enters puberty.",
            "type": "event",
            "selections": [
              {
                "title": {
                  "en": "Talk to a trusted adult."
                },
                "effects": {
                  "agency": 1,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Keep it a Secret."
                },
                "effects": {
                  "agency": -1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Learning About Contraceptives"
            },
            "description": {
              "en": "You have been having sex with your boyfriend but not using contraception. Luckily, you haven’t gotten pregnant, but you are scared you might. What do you do?"
            },
            "image": "/images/06-highschool_contraceptives-discussion.webp",
            "imageAlt": "Black-and-white comic book style image of a young girl discussing contraception options with friends.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": []
              }
            },
            "selections": [
              {
                "title": {
                  "en": "Continue having unprotected sex."
                },
                "effects": {
                  "agency": -2,
                  "health": -2,
                  "education": -2,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "Ask your boyfriend to get protection, and talk a trusted adult about your situation."
                },
                "effects": {
                  "health": 2,
                  "education": 1,
                  "tags": {
                    "add": []
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "Peer Pressure to Skip School"
            },
            "description": {
              "en": "One Friday at school, some popular girls in your class tell you to miss school and go with them to a party. What do you do?"
            },
            "image": "/images/06-highschool_peer-pressure-party.webp",
            "imageAlt": "Black-and-white comic book style image of a student being invited to skip school by classmates.",
            "type": "event",
            "requirements": {
              "tags": {
                "and": []
              }
            },
            "selections": [
              {
                "title": {
                  "en": "You say no."
                },
                "effects": {
                  "education": 20,
                  "tags": {
                    "add": []
                  }
                }
              },
              {
                "title": {
                  "en": "You say yes."
                },
                "effects": {
                  "education": -20,
                  "tags": {
                    "add": [
                      "party_2"
                    ]
                  }
                }
              }
            ]
          },
          {
            "title": {
              "en": "A Risky Party"
            },
            "description": {
              "en": "You discover that the 'party' is in the bush, and everyone is smoking and drinking. A boy you like tells you that you are beautiful and asks you to smoke. What do you do?"
            },
            "image": "/images/06-highschool_risky-party.webp",
            "imageAlt": "Black-and-white comic book style image of a party in an unsafe location.",
            "type": "trigger",
            "requirements": {
              "tags": {
                "and": [
                  "party_2"
                ]
              }
            },
            "selections": [
              {
                "title": {
                  "en": "You say no and leave."
                },
                "effects": {
                  "agency": 20,
                  "education": 10,
                  "tags": {
                    "add": [
                      "left-party"
                    ]
                  }
                }
              },
              {
                "title": {
                  "en": "You say no to smoking, but decide to stay."
                },
                "effects": {
                  "agency": -10,
                  "education": -10,
                  "tags": {
                    "add": [
                      "party_3"
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "title": "Late Highschool",
        "requirements": {
          "age": {
            "gt": 16,
            "lt": 19
          }
        },
        "slides": []
      },
      {
        "title": "Early Adulthood",
        "requirements": {
          "age": {
            "gt": 19,
            "lt": 24
          }
        },
        "slides": []
      },
      {
        "title": "Adulthood ",
        "requirements": {
          "age": {
            "gt": 18,
            "lt": 27
          }
        },
        "slides": []
      },
      {
        "title": "End Game ",
        "requirements": {
          "age": {
            "gt": 24
          }
        },
        "slides": []
      }
    ]
  }
];