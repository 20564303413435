import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Text,
} from "@chakra-ui/react"
import { HamburgerIcon, RepeatIcon, DeleteIcon } from "@chakra-ui/icons"
import GameSlidePage from "./pages/GameSlidePage"
import { resetGame } from "./features/game/gameSlice"
import { useAppDispatch } from "./app/hooks"
import { MenuCharacterTextEditor } from "./features/character/CharacterText"
import { MenuCharacterAvatarEditor } from "./features/character/CharacterAvatarEditor"
import { resetPersistedState } from "./app/store"
import * as Sentry from "@sentry/react"

// This version should match the APP_VERSION in store.ts
const APP_VERSION = "1.0.2"

// Create a custom fallback component for error boundaries
const ErrorFallback = ({ componentName }: { componentName: string }) => (
  <Box p={4} bg="red.50" color="red.500" borderRadius="md">
    <Text fontWeight="bold">Something went wrong in {componentName}</Text>
    <Text fontSize="sm">We've been notified and are working on a fix</Text>
  </Box>
)

const App = () => {
  const dispatch = useAppDispatch()

  const onReset = () => {
    dispatch(resetGame())
  }

  const onPurgeStorage = () => {
    resetPersistedState()
    window.location.reload()
  }

  return (
    <Box position="relative" height="100vh">
      {/* Navigation Menu */}
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          size="sm"
          m={2}
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
        />
        <MenuList>
          <Sentry.ErrorBoundary
            fallback={<ErrorFallback componentName="Character Avatar Editor" />}
          >
            <MenuCharacterAvatarEditor />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary
            fallback={<ErrorFallback componentName="Character Text Editor" />}
          >
            <MenuCharacterTextEditor />
          </Sentry.ErrorBoundary>
          <MenuItem onClick={onReset}>
            <RepeatIcon mr={2} /> Restart Game
          </MenuItem>
          <MenuItem onClick={onPurgeStorage}>
            <DeleteIcon mr={2} /> Clear All Data
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Container for GameSlidePage */}
      <Box height="100%">
        <Sentry.ErrorBoundary
          fallback={<ErrorFallback componentName="Game Slide Page" />}
        >
          <GameSlidePage />
        </Sentry.ErrorBoundary>
      </Box>
      <Box>
        {/* Version indicator */}
        <Text
          position="absolute"
          bottom={2}
          right={2}
          fontSize="xs"
          color="gray.500"
        >
          v{APP_VERSION}
        </Text>
      </Box>
    </Box>
  )
}

export default App
