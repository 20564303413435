import type { Action, ThunkAction } from "@reduxjs/toolkit"

import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import * as Sentry from "@sentry/react"
// Slices
import { counterSlice } from "../features/example/counter/counterSlice"
import { gameSlice } from "../features/game/gameSlice"
import { characterSlice } from "../features/character/characterSlice"
import { configSlice } from "../features/config/configSlice"

// App version - increment this when making data structure changes
const APP_VERSION = "1.0.2"
const APP_VERSION_KEY = "appVersion"

// Version check function to clear persisted state when version changes
const versionCheck = () => {
  const savedVersion = localStorage.getItem(APP_VERSION_KEY)
  if (savedVersion !== APP_VERSION) {
    localStorage.clear()
    localStorage.setItem(APP_VERSION_KEY, APP_VERSION)
  }
}

// Run version check when the store is created
versionCheck()

const persistConfig = {
  key: "root",
  version: 1,
  storage,
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
export const rootReducer = combineSlices(
  characterSlice,
  configSlice,
  counterSlice,
  gameSlice,
)
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: persistedReducer,
    enhancers: getDefaultEnhancers => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer)
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()
export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>

// Method to clear persisted state programmatically
export const resetPersistedState = () => {
  persistor.purge()
}
