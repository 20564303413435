import { GameLogicCharacter } from "@types"
import React, { useState } from "react"
import * as Sentry from "@sentry/react"

type InputAvatar = GameLogicCharacter["avatar"]

type EditedAvatarContextType = {
  editedAvatar: InputAvatar
  setEditedAvatar: (
    avatar: InputAvatar | ((prev: InputAvatar) => InputAvatar),
  ) => void
}

const EditedAvatarContext = React.createContext<
  EditedAvatarContextType | undefined
>(undefined)

// Error fallback component
const ErrorFallback = ({ componentName }: { componentName: string }) => (
  <div
    style={{
      padding: "1rem",
      backgroundColor: "#FFF5F5",
      color: "#E53E3E",
      borderRadius: "0.375rem",
    }}
  >
    <p style={{ fontWeight: "bold" }}>
      Something went wrong in {componentName}
    </p>
    <p style={{ fontSize: "0.875rem" }}>
      We've been notified and are working on a fix
    </p>
  </div>
)

export const useEditedAvatar = () => {
  const context = React.useContext(EditedAvatarContext)
  if (context === undefined) {
    throw new Error(
      "useEditedAvatar must be used within an EditedAvatarProvider",
    )
  }
  return context
}

export const EditedAvatarProvider: React.FC<{
  data: InputAvatar
  children: React.ReactNode
}> = ({ data, children }) => {
  const [editedAvatar, setEditedAvatar] = useState<InputAvatar>(data)

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorFallback componentName="Avatar Editor Context" />}
    >
      <EditedAvatarContext.Provider value={{ editedAvatar, setEditedAvatar }}>
        {children}
      </EditedAvatarContext.Provider>
    </Sentry.ErrorBoundary>
  )
}
