import { Heading } from "@chakra-ui/react"
import { useAppSelector } from "../../app/hooks"
import { selectGameAge } from "./gameSlice"

export const AgeCounter = () => {
  // const dispatch = useAppDispatch()
  const turnAge = useAppSelector(selectGameAge)

  return <Heading>Age: {Math.round(turnAge)}</Heading>
}
