import "./sentry"

import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import "./index.css"
import { persistor, store } from "./app/store"
import { PersistGate } from "redux-persist/integration/react"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { GameTriggerProvider } from "./hooks/GameTriggers"
import { AvatarEditorModalProvider } from "./features/character/useAvatarEditorModal"

// // Register service worker for PWA
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js').then(registration => {
//       // Check if there's a new service worker
//       registration.addEventListener('updatefound', () => {
//         // A new service worker is installing
//         const newWorker = registration.installing
//         if (newWorker) {
//           newWorker.addEventListener('statechange', () => {
//             // If the new worker is activated, reload the page to use the new version
//             if (newWorker.state === 'activated') {
//               // Clear persisted data on new version
//               resetPersistedState()
//               window.location.reload()
//             }
//           })
//         }
//       })
//     })

//     // Check for updates on page load
//     navigator.serviceWorker.addEventListener('controllerchange', () => {
//       // The controller has changed, likely meaning a new service worker took over
//       window.location.reload()
//     })
//   })
// }

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  // 2. Extend the theme to include custom colors, fonts, etc
  const colors = {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  }

  const theme = extendTheme({ colors })

  root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GameTriggerProvider>
              <AvatarEditorModalProvider>
                <App />
              </AvatarEditorModalProvider>
            </GameTriggerProvider>
          </PersistGate>
        </Provider>
      </ChakraProvider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
