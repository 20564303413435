import { useAppSelector } from "../app/hooks"
import { selectConfigLanguage } from "../features/config/configSlice"
import type { TextObject } from "@types"
import { Text } from "@chakra-ui/react"

export const GameText = (props: { text: TextObject }) => {
  const configLanguage = useAppSelector(selectConfigLanguage)
  if (props.text[configLanguage] !== undefined) {
    return <Text>{props.text[configLanguage]}</Text>
  } else {
    return <Text>{props.text["en"]}</Text>
  }
}
