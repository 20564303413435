import React, { useContext, useState } from "react"

type AvatarEditorModalContextType = {
  openModal: () => void
  closeModal: () => void
  isOpen: boolean
}

const AvatarEditorModalContext =
  React.createContext<AvatarEditorModalContextType>({
    openModal: () => {},
    closeModal: () => {},
    isOpen: false,
  })

export const AvatarEditorModalProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <AvatarEditorModalContext.Provider
      value={{ openModal, closeModal, isOpen }}
    >
      {children}
    </AvatarEditorModalContext.Provider>
  )
}

export const useAvatarEditorModal = () => {
  const context = useContext(AvatarEditorModalContext)
  if (!context) {
    throw new Error(
      "useAvatarEditorModal must be used within an AvatarEditorModalProvider",
    )
  }
  return context
}
