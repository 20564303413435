import type { PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../app/createAppSlice"

import type { GameLogicModifiers, GameLogicState, SlideGeneric } from "@types"
import defaultGameData from "../default"
import { u } from "framer-motion/client"

// Load default from JSON
export const gameInitialState: GameLogicState = defaultGameData.start

export const gameSlice = createAppSlice({
  name: "game",
  initialState: gameInitialState,
  reducers: create => ({
    appendHistory: create.reducer(
      (state, action: PayloadAction<GameLogicState["history"][0]>) => {
        state.history.push(action.payload)
      },
    ),
    incrementGameTurn: create.reducer(state => {
      state.turn += 1
    }),
    resetGame: create.reducer(() => gameInitialState),
    updateGame: create.reducer(
      (state, action: PayloadAction<GameLogicModifiers>) => {
        state.age += action.payload?.age ?? 0
        state.agency += action.payload?.agency ?? 0
        state.education += action.payload?.education ?? 0
        state.health += action.payload?.health ?? 0
        state.relationship += action.payload?.relationship ?? 0
      },
    ),
    updateDuration: create.reducer((state, action: PayloadAction<number>) => {
      state.duration += action.payload
    }),
    updateGameSlide: create.reducer(
      (state, action: PayloadAction<SlideGeneric>) => {
        state.slide = action.payload
      },
    ),
    updateAddGameTags: create.reducer(
      (state, action: PayloadAction<string>) => {
        if (!state.tags.includes(action.payload)) {
          state.tags.push(action.payload)
        }
      },
    ),
    updateRemoveGameTags: create.reducer(
      (state, action: PayloadAction<string>) => {
        if (state.tags.includes(action.payload)) {
          state.tags = state.tags.filter(tag => tag !== action.payload)
        }
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    // State
    selectIsDone: state => state?.isDone ?? false,
    selectGameAge: state => state.age,
    selectGameAgency: state => state.agency,
    selectGameEducation: state => state.education,
    selectGameHealth: state => state.health,
    selectGameRelationship: state => state.relationship,
    selectGameTags: state => state.tags,
    // Slide
    selectSlide: state => state.slide,
    // Statistics
    selectDuration: state => state.duration,
    selectHistory: state => state.history,
    selectTurn: state => state.turn,
    selectScore: state =>
      Math.min(state.agency, 25) +
      Math.min(state.education, 25) +
      Math.min(state.health, 25) +
      Math.min(state.relationship, 25),
  },
})

export const {
  appendHistory,
  incrementGameTurn,
  updateGame,
  updateGameSlide,
  updateAddGameTags,
  updateRemoveGameTags,
  resetGame,
} = gameSlice.actions

export const {
  selectIsDone,
  selectGameAge,
  selectGameAgency,
  selectGameEducation,
  selectGameHealth,
  selectGameRelationship,
  selectGameTags,
  // Slide
  selectSlide,
  // Statistics
  selectDuration,
  selectHistory,
  selectTurn,
  selectScore,
} = gameSlice.selectors
